import React from 'react';

// components
import Image from 'src/components/Image';
import { Button } from 'src/components/Inputs';

interface Props {
  logos: { img: string; alt: string }[];
  text: string;
}

function HomepageSocialProofLogo({ logo }) {
  return (
    <React.Fragment>
      <div className='cell social-proof-logo social-proof-logo--no-padding'>
        <Image src={logo.img} alt={logo.alt} width={190} height={90} />
      </div>
    </React.Fragment>
  );
}

const HomepageSocialProof: React.FC<Props> = (props) => (
  <div className='social-proof-wrapper'>
    <div className='grid-container'>
      <div className='grid-x grid-margin-x align-right'>
        <div className='cell small-12 medium-8 large-7 social-proof-container'>
          <h6 className='section-title'>Who we work with</h6>
          <div className='grid-x small-up-3'>
            {props.logos.map((logo, i) => (
              <HomepageSocialProofLogo
                key={`logos-${i}`}
                logo={logo}
              ></HomepageSocialProofLogo>
            ))}
          </div>
          <p className='social-proof-text'>{props.text}</p>
          <Button
            className='social-proof-button'
            href='/products'
            secondary
            invertedColors
          >
            Our products
          </Button>
        </div>
        <div className='accent-brush accent-brush-0'>
          <Image
            src='/assets/img/accents/homepage-social-proof-brush-right-bottom.png'
            alt=''
            isLazy={true}
          />
        </div>
        <div className='accent-brush accent-brush-1'>
          <Image
            src='/assets/img/accents/homepage-social-proof-brush-right-top.png'
            alt=''
            isLazy={true}
          />
        </div>
        <div className='accent-brush accent-brush-2'>
          <Image
            src='/assets/img/accents/homepage-social-proof-brush-left.png'
            alt=''
            isLazy={true}
          />
        </div>
      </div>
    </div>
  </div>
);

export default HomepageSocialProof;
