import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { default as SectionHeader } from 'src/components/features/feature-section-title';
import ArticleExcerpt from './ArticleExcerpt';

const propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  layout: PropTypes.string,
  hasSectionHeader: PropTypes.bool,
  sectionHeaderData: PropTypes.objectOf(PropTypes.string),
};

/**
 * @param {string} layout - configuration prop for type of grid layout
 * @param {number} i - index of the item for which we want the classnames
 * @returns {string} the grid classnames string
 */
export const getGridClasses = (layout, i) => {
  if (layout === 'two-column') {
    return i < 1 ? 'large-5' : 'large-5 large-offset-2';
  }
};

const RelatedArticles = ({
  items,
  layout = 'two-column',
  hasSectionHeader = false,
  sectionHeaderData = {},
}) => {
  const itemBaseClass = ['cell', 'small-12'];

  return (
    <div className='grid-container section-container'>
      {hasSectionHeader && <SectionHeader {...sectionHeaderData} />}
      <div className='grid-x grid-margin-x'>
        {items.map((item, i) => (
          <div
            key={`item-${i}`}
            className={cx(...itemBaseClass, getGridClasses(layout, i))}
          >
            <ArticleExcerpt {...item} />
          </div>
        ))}
      </div>
    </div>
  );
};

RelatedArticles.propTypes = propTypes;
export default RelatedArticles;
