import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from './ArticleExcerpt.module.scss';
import { Link } from 'src/components/Inputs';
import ArrowRightSingleSmallGary from 'src/components/SVGs/Icons/ArrowRightSingleSmallGray';
import Image from 'src/components/Image';

const propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  cta: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
};

const ArticleExcerpt = ({ title, url, cta, description, img }) => {
  return (
    <Link href={url}>
      <a className={styles.article}>
        <Image src={img} alt={title} className={styles.image} />
        <h4 className={styles.title}>{title}</h4>
        <p dangerouslySetInnerHTML={{ __html: description }} />
        <p className={cx('feature-section-link-text', styles.cta)}>
          {cta}{' '}
          <span aria-hidden='true'>
            <ArrowRightSingleSmallGary />
          </span>
        </p>
      </a>
    </Link>
  );
};

ArticleExcerpt.propTypes = propTypes;
export default ArticleExcerpt;
